
import { defineComponent, ref, onMounted } from 'vue';
import { api } from '@/services/Api';
import { ShareDocumentRequest, IdName } from '@/models/Interfaces';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { DocumentType } from '@/models/Enums';
import q from 'q';

export default defineComponent({
    name: 'ShareDocument',
    props: {
        documentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {};
    },
    async setup(props: any) {
        const { t } = useI18n();
        const smsReceiver = ref('');
        const emailSubject = ref('');
        const emailReceiver = ref('');
        const sendEmail = ref(false);
        const sendSms = ref(false);
        const message = ref('');
        const locale = ref('he-IL');
        const openedLocaleBlock = ref(false);
        const loaded = ref(false);
        const IncludeDocs = ref(false);
        const canIncludeDocs = ref(false);

        function getDocName(type: DocumentType, isBusiness: boolean, isCredit: boolean, providerDocumentTypeName: string) {
            let docName = '';
            if (isBusiness) {
                docName = t('documentTypes.Business' + (isCredit && type == DocumentType.Invoice ? 'Credit' : '') + DocumentType[type]);
            } else {
                if (type == DocumentType.Custom) {
                    docName = providerDocumentTypeName;
                } else {
                    docName = t('documentTypes.' + (isCredit && type == DocumentType.Invoice ? 'Credit' : '') + DocumentType[type]);
                }
            }
            return docName;
        }

        async function getDocumentData() {
            const apiResult = await api.GetDocumentShareData(props.documentId);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            const result = apiResult.data;

            if (result) {
                smsReceiver.value = result.smsReceiver ?? '';
                emailReceiver.value = result.emailReceiver;
                if (result.customerName) {
                    message.value = t('share.document.default.msg', [
                        result.customerName,
                        getDocName(result.docType, result.isBusiness, result.isCredit, result.providerDocumentTypeName),
                        result.docNumber
                    ]);
                } else {
                    message.value = t('share.document.default.msg-withoutcustomer', [
                        getDocName(result.docType, result.isBusiness, result.isCredit, result.providerDocumentTypeName),
                        result.docNumber
                    ]);
                }

                if (result.isBusiness) {
                    if (result.docType == DocumentType.Invoice) {
                        canIncludeDocs.value = true;
                        emailSubject.value = t('message.BusinessInvoiceEmailSubject', [result.docNumber]);
                    } else if (result.docType == DocumentType.Receipt) {
                        emailSubject.value = t('message.BusinessReceiptEmailSubject', [result.docNumber]);
                    }
                } else {
                    if (result.docType == DocumentType.Invoice) {
                        emailSubject.value = t('message.InvoiceEmailSubject', [result.docNumber]);
                    } else if (result.docType == DocumentType.Receipt) {
                        emailSubject.value = t('message.ReceiptEmailSubject', [result.docNumber]);
                    } else if (result.docType == DocumentType.OrderSummary) {
                        emailSubject.value = t('message.OrderSummaryEmailSubject', [result.docNumber]);
                    }
                }
            }
        }

        function changeLang(lang: string) {
            locale.value = lang;
            openedLocaleBlock.value = false;
        }
        async function send() {
            if (!sendEmail.value && !sendSms.value) {
                return;
            }
            if (!message.value) {
                return;
            }
            if (sendEmail.value) {
                if (!emailSubject.value) {
                    return;
                }
                if (!emailReceiver.value) {
                    return;
                }
            }
            if (sendSms.value) {
                if (!smsReceiver.value) {
                    return;
                }
            }

            const req: ShareDocumentRequest = {
                id: props.documentId,
                sendEmail: sendEmail.value,
                sendSms: sendSms.value,
                message: message.value,
                emailReceiver: emailReceiver.value,
                emailSubject: emailSubject.value,
                smsReceiver: smsReceiver.value.toString(),
                lang: locale.value,
                timezone: 0,
                includeDocs: IncludeDocs.value
            };
            console.log(123, req);

            const apiResult = await api.ShareDocument(req);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Document was shared',
                showConfirmButton: false,
                timer: 1500
            });

            router.go(-1);
        }

        function close() {
            router.go(-1);
        }
        const onMountedCall = async () => {
            await getDocumentData();
            loaded.value = true;
        };
        onMounted(onMountedCall);
        return {
            locale,
            changeLang,
            openedLocaleBlock,
            sendEmail,
            sendSms,
            send,
            message,
            emailReceiver,
            emailSubject,
            smsReceiver,
            close,
            loaded,
            IncludeDocs,
            canIncludeDocs
        };
    }
});
